<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M22.75,23.248a3.723,3.723,0,0,0-5.438-.574,5.225,5.225,0,0,0-10.121,0,3.722,3.722,0,0,0-5.439.576"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M21.252,22.064V8.387A7.428,7.428,0,0,0,14.052.75h-3.6a7.428,7.428,0,0,0-7.2,7.637V22.064"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="12.252"
      y1="15.75"
      x2="12.252"
      y2="8.25"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="6.252"
      y1="15.75"
      x2="6.252"
      y2="8.25"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M6.252,8.25H7.377a1.875,1.875,0,1,1,0,3.75H6.252"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="9.252"
      y1="15.75"
      x2="7.002"
      y2="12"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="15.252"
      y1="15.75"
      x2="15.252"
      y2="8.25"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15.252,8.25H16a2.25,2.25,0,0,1,0,4.5h-.75"
    />
  </svg>
</template>
